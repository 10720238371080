import React, { useState, useEffect } from 'react';
import { getChannelDetails } from './api/youtube';
import { ArrowUpRightIcon } from '@heroicons/react/20/solid';

import { AnimatedCounter } from  'react-animated-counter';

const LiveSubCount = () => {
  const [channelDetails, setChannelDetails] = useState({
    subscriberCount: null,
    channelName: '',
    profilePicture: ''
  });
  const [channelInput, setChannelInput] = useState(''); // For user input
  const [channelId, setChannelId] = useState('UCX6OQ3DkcsbYNE6H8uQQuVA'); // Default channel ID

  const fetchChannelDetails = async (id) => {
    const details = await getChannelDetails(id);
    setChannelDetails(details);
  };

  useEffect(() => {
    fetchChannelDetails(channelId);
    const intervalId = setInterval(() => fetchChannelDetails(channelId), 3000);

    return () => clearInterval(intervalId);
  }, [channelId]);

  const handleInputChange = (e) => {
    setChannelInput(e.target.value);
  };

  const handleFetchNewChannel = () => {
    setChannelId(channelInput);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-black text-white">
      <div className="flex flex-col items-center space-y-4">

        <img 
          src={channelDetails.profilePicture} 
          alt="Channel Thumbnail" 
          className="w-24 h-24 rounded-full border-4 border-white" 
        />
        <h1 className="text-4xl font-semibold">{channelDetails.channelName}</h1>

        <AnimatedCounter value={Math.round(channelDetails.subscriberCount)} decimalPrecision={0} includeCommas={true} color="white" fontSize="80px" />
        <p className="text-xl">Subscribers</p>
        
        <a 
          href={`https://www.youtube.com/channel/${channelId}?sub_confirmation=1`} 
          target="_blank" 
          rel="noopener noreferrer"
          className="inline-flex items-center gap-x-2 rounded-md bg-red-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
        >
          Subscribe
          <ArrowUpRightIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
        </a>

        <div className="flex pt-12 gap-4">
        <input
          type="text"
          placeholder="Enter Channel ID"
          value={channelInput}
          onChange={handleInputChange}
          className="p-2 rounded text-black "
        />
        <button
          onClick={handleFetchNewChannel}
          className="inline-flex items-center gap-x-2 rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
        >
          Fetch Channel
        </button>

        </div>
      </div>
    </div>
  );
};

export default LiveSubCount;
