import axios from 'axios';

const API_KEY = process.env.REACT_APP_YOUTUBE_API;

export const getChannelDetails = async (channelId) => {
  const urlYoutube = channelId.startsWith('UC')
    ? `https://www.googleapis.com/youtube/v3/channels?part=statistics,snippet&id=${channelId}&key=${API_KEY}`
    : `https://www.googleapis.com/youtube/v3/channels?part=statistics,snippet&forUsername=${channelId}&key=${API_KEY}`;

    const urlSocialblade = channelId.startsWith('UC')
    ? `https://api.socialcounts.org/youtube-live-subscriber-count/${channelId}`
    : `https://api.socialcounts.org/youtube-live-subscriber-count/${channelId}`;


  const responseYoutube = await axios.get(urlYoutube);
  const responseSocialblade = await axios.get(urlSocialblade);

  const dataYoutube = responseYoutube.data.items[0];

  const dataSocialblade = responseSocialblade.data.est_sub;

  return {
    subscriberCount: dataSocialblade,
    channelName: dataYoutube.snippet.title,
    profilePicture: dataYoutube.snippet.thumbnails.default.url
  };
};
